<template>
  <div class="grid grid-cols-3">
    <div class="col-start-2 flex m-auto">
      <button class="w-8 h-8 color_enabled" @click="calculateDelta('up')">
        <ArrowUpIcon/>
      </button>
    </div>
    <div class="col-start-1 flex m-auto">
      <button class="w-8 h-8 color_enabled" @click="calculateDelta('left')">
        <ArrowLeftIcon/>
      </button>
    </div>
    <div>
      <StyledInput v-model="step" type="number" :step="0.01" placeholder="Step" :floating="true"/>
    </div>
    <div class="flex m-auto">
      <button class="w-8 h-8 color_enabled" @click="calculateDelta('right')">
        <ArrowRightIcon/>
      </button>
    </div>
    <div class="col-start-2 flex m-auto">
      <button class="w-8 h-8 color_enabled" @click="calculateDelta('down')">
        <ArrowDownIcon/>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ArrowUpIcon, ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon} from '@heroicons/vue/24/outline'
</script>

<script lang="ts">
import {defineComponent} from "vue";
import StyledInput from './StyledInput.vue';
import { useConfig } from '@/main';

export default defineComponent({
  name: "ArrowControls",
  data() {
    return {
      step: Number(localStorage.getItem('node_arrows_step')) || 0.1,
      mapTheta: 0,
      reversionFactor: 1
    }
  },
  props: {
    mapId: {
      type: Number,
      default: 0
    },
  },
  watch: {
    mapId(newVal) {
      this.mapTheta = Number(useConfig().config.configuration.maps[Number(newVal)].map_rotate) * Math.PI / 180;
      this.reversionFactor = Number(useConfig().config.configuration.maps[Number(newVal)].scanner_reversion_factor);
    },
    step(newVal) {
      localStorage.setItem('node_arrows_step', newVal)
    }
  },
  mounted() {
    this.mapTheta = Number(useConfig().config.configuration.maps[Number(this.mapId)].map_rotate) * Math.PI / 180
  },
  methods: {
    calculateDelta(direction: String) {
      const calculatedDelta = {
        x: 0,
        y: 0
      };
      switch (direction) {
        case 'up':
          console.log('up');
          calculatedDelta.x = Math.round(this.step * Math.sin(-Number(this.mapTheta))*10000)/10000 * this.reversionFactor;
          calculatedDelta.y = Math.round(this.step * Math.cos(-Number(this.mapTheta))*10000)/10000;
          break;
        case 'left':
          console.log('left');
          calculatedDelta.x = -Math.round(this.step * Math.cos(Number(this.mapTheta))*10000)/10000 * this.reversionFactor;
          calculatedDelta.y = -Math.round(this.step * Math.sin(Number(this.mapTheta))*10000)/10000;
          break;
        case 'right':
          console.log('right');
          calculatedDelta.x = Math.round(this.step * Math.cos(Number(this.mapTheta))*10000)/10000 * this.reversionFactor;
          calculatedDelta.y = Math.round(this.step * Math.sin(Number(this.mapTheta))*10000)/10000;
          break;
        case 'down':
          console.log('down');
          calculatedDelta.x = -Math.round(this.step * Math.sin(-Number(this.mapTheta))*10000)/10000 * this.reversionFactor;
          calculatedDelta.y = -Math.round(this.step * Math.cos(-Number(this.mapTheta))*10000)/10000;
          break;
        default:
          console.log('Wrong string')
      }
      this.$emit('deltaChanged', calculatedDelta);
    },
  },
})
</script>

<style scoped>

</style>
