import {reactive, UnwrapNestedRefs} from 'vue'

class VehicleState {
    get connected(): boolean {
        return this._connected;
    }

    get name(): string | null {
        return this._name;
    }

    set connected(value: boolean) {
        this._connected = value;
    }

    set name(value: string | null) {
        this._name = value;
    }

    static OPERATING_MODE_AUTOMATIC = "AUTOMATIC"
    static OPERATING_MODE_SEMIAUTOMATIC = "SEMIAUTOMATIC"
    static OPERATING_MODE_MANUAL = "MANUAL"
    static OPERATING_MODE_SERVICE = "SERVICE"
    static OPERATING_MODE_TEACH_IN = "TEACHIN"
    headerId: 0 | undefined;
    timestamp: string | undefined;
    version: string | undefined;
    manufacturer: string | undefined;
    serialNumber: string | undefined;
    orderId: string | undefined;
    orderUpdateId: 0 | undefined;
    zoneSetId: string | undefined;
    lastNodeId: string | undefined;
    lastNodeSequenceId: 0 | undefined;
    nodeStates: NodeState[] = [];
    edgeStates: EdgeState[] = [];
    agvPosition: AgvPosition | undefined;
    velocity: Velocity | undefined;
    loads: Load[] = [];
    driving: boolean | undefined;
    paused: boolean | undefined;
    newBaseRequest: boolean | undefined;
    distanceSinceLastNode: number | undefined;
    operatingMode: string | undefined;
    actionStates: ActionState[] = [];
    batteryState: Battery | undefined;
    safetyState: SafetyState | undefined;
    errors: any[] = [];
    information: any[] = [];
    private _name: string | null = null;
    private _connected: boolean = false
    show_on_map: boolean | null = null;
    factsheet: Factsheet | null;
    connection: Connection | null;

    constructor(json_obj: any) {
        this.updateState(json_obj)
        this.factsheet = null;
        this.connection = null;
    }

    updateVisualization(json_obj: any) {
      this.timestamp = json_obj?.timestamp;
      this.version = json_obj?.version;
      this.manufacturer = json_obj?.manufacturer;
      this.serialNumber = json_obj?.serialNumber;
      this.agvPosition = new AgvPosition(json_obj?.agvPosition);
    }

    updateState(json_obj: any) {
        if (!this.timestamp || Number(json_obj.timestamp) > Number(this.timestamp)) {
          this.version = json_obj?.version;
          this.manufacturer = json_obj?.manufacturer;
          this.serialNumber = json_obj?.serialNumber;
          this.agvPosition = new AgvPosition(json_obj?.agvPosition);
          this.timestamp = json_obj?.timestamp;
        }

        this.headerId = json_obj?.headerId;
        this.orderId = json_obj?.orderId;
        this.orderUpdateId = json_obj?.orderUpdateId;
        this.zoneSetId = json_obj?.zoneSetId;
        this.lastNodeId = json_obj?.lastNodeId;
        this.lastNodeSequenceId = json_obj?.lastNodeSequenceId;
        this.nodeStates = [];
        json_obj?.nodeStates?.forEach((el: any) => this.nodeStates.push(new NodeState(el)));
        this.edgeStates = [];
        json_obj?.edgeStates?.forEach((el: any) => this.edgeStates.push(new EdgeState(el)));
        this.velocity = new Velocity(json_obj?.velocity);
        this.loads = [];
        json_obj?.loads?.forEach((el: any) => this.loads.push(new Load(el)));
        this.driving = json_obj?.driving;
        this.paused = json_obj?.paused;
        this.newBaseRequest = json_obj?.newBaseRequest;
        this.distanceSinceLastNode = json_obj?.distanceSinceLastNode;
        this.actionStates = [];
        json_obj?.actionStates?.forEach((el: any) => this.actionStates.push(new ActionState(el)));
        this.batteryState = new Battery(json_obj?.batteryState);
        this.operatingMode = json_obj?.operatingMode;
        this.errors = [];
        json_obj?.errors?.forEach((el: any) => this.errors.push(new AgvMessage(el, AgvMessage.type_error)));
        this.information = [];
        json_obj?.information?.forEach((el: any) => this.information.push(new AgvMessage(el, AgvMessage.type_info)));
        this.safetyState = new SafetyState(json_obj?.safetyState);
    }

    hasActiveOrder() {
        return this.nodeStates.length > 0 ||
            this.edgeStates.length > 0 ||
            !this.finishedAllActions();
    }

    finishedAllActions() {
        var finished_all = true;
        this.actionStates.every(el => {
            finished_all = finished_all && (el.actionStatus == ActionState.ACTION_STATUS_FINISHED ||
                el.actionStatus == ActionState.ACTION_STATUS_FAILED);
            return finished_all;
        });
        return finished_all;
    }
}

class NodeState {
    readonly node_id: "";
    readonly sequence_id: 0;
    readonly node_description: "";
    readonly node_position: Position;
    readonly released: false;

    constructor(json_obj: any) {
        this.node_id = json_obj?.nodeId ?? "";
        this.sequence_id = json_obj?.sequenceId ?? "";
        this.node_description = json_obj?.nodeDescription ?? "";
        this.node_position = new Position(json_obj?.nodePosition);
        this.released = json_obj?.released ?? false;
    }
}

class Position {
    readonly x: number;
    readonly y: number;
    readonly theta: number;
    readonly allowed_deviation_xy: number;
    readonly allowed_deviation_theta: number;
    readonly map_id: string;
    readonly map_description: string;

    constructor(json_obj: any) {
        this.x = json_obj?.x ?? 0;
        this.y = json_obj?.y ?? 0;
        this.theta = json_obj?.theta ?? null;
        this.allowed_deviation_xy = json_obj?.allowedDeviationXY ?? null;
        this.allowed_deviation_theta = json_obj?.allowedDeviationTheta ?? null;
        this.map_id = json_obj?.mapId ?? "";
        this.map_description = json_obj?.mapDescription ?? null;
    }
}

class EdgeState {
    get trajectory(): Trajectory {
        return this._trajectory;
    }

    set trajectory(value: Trajectory) {
        this._trajectory = value;
    }

    readonly edgeId: string;
    readonly sequenceId: number;
    readonly edgeDescription: string;
    readonly released: boolean;
    private _trajectory: Trajectory;

    constructor(json_obj: any) {
        this.edgeId = json_obj?.edgeId ?? "";
        this.sequenceId = json_obj?.sequenceId ?? 0;
        this.edgeDescription = json_obj?.edgeDescription ?? "";
        this.released = json_obj?.released ?? false;
        this._trajectory = new Trajectory(json_obj?.trajectory);
    }
}

class Trajectory {
    readonly degree: number;
    readonly knotVector: [];
    readonly controlPoints: [];

    constructor(json_obj: any) {
        this.degree = json_obj?.degree ?? 1;
        this.knotVector = json_obj?.knotVector ?? [];
        this.controlPoints = json_obj?.controlPoints ?? [];
    }
}

class AgvPosition {
    readonly positionInitialized: boolean;
    readonly localizationScore;
    readonly deviationRange;
    readonly x;
    readonly y;
    readonly theta;
    readonly mapId;
    readonly mapDescription;

    constructor(json_obj: any) {
        this.positionInitialized = json_obj?.positionInitialized;
        this.localizationScore = json_obj?.localizationScore;
        this.deviationRange = json_obj?.deviationRange;
        this.x = json_obj?.x;
        this.y = json_obj?.y;
        this.theta = json_obj?.theta;
        this.mapId = json_obj?.mapId;
        this.mapDescription = json_obj?.mapDescription;
    }
}

class Velocity {
    readonly vx: number;
    readonly vy: number;
    readonly omega: number;

    constructor(js: any) {
        this.vx = js?.vx;
        this.vy = js?.vy;
        this.omega = js?.omega;
    }
}

class Load {
    readonly loadId;
    readonly loadType;
    readonly loadPosition;
    readonly boundingBoxReference;
    readonly loadDimensions;
    readonly weight;

    constructor(json_obj: any) {
        this.loadId = json_obj?.loadId;
        this.loadType = json_obj?.loadType;
        this.loadPosition = json_obj?.loadPosition;
        this.boundingBoxReference = new BoundingBoxReference(json_obj.boundingBoxReference);
        this.loadDimensions = new LoadDimensions(json_obj.loadDimensions);
        this.weight = json_obj?.weight;
    }
}

class BoundingBoxReference {
    private x;
    private y;
    private z;
    private theta;

    constructor(json_obj: any) {
        this.x = json_obj?.x;
        this.y = json_obj?.y;
        this.z = json_obj?.z;
        this.theta = json_obj?.theta;
    }
}

class LoadDimensions {
    private length;
    private width;
    private height;

    constructor(json_obj: any) {
        this.length = json_obj?.length ?? 0;
        this.width = json_obj?.width ?? 0;
        this.height = json_obj?.height ?? 0;
    }
}

class ActionState {
    // action status enum
    static ACTION_STATUS_WAITING = "WAITING";
    static ACTION_STATUS_INITIALIZING = "INITIALIZING";
    static ACTION_STATUS_RUNNING = "RUNNING";
    static ACTION_STATUS_PAUSED = "PAUSED";
    static ACTION_STATUS_FINISHED = "FINISHED";
    static ACTION_STATUS_FAILED = "FAILED";

    // action blocking type enum
    static BLOCKING_TYPE_NONE = "NONE";
    static BLOCKING_TYPE_SOFT = "SOFT";
    static BLOCKING_TYPE_HARD = "HARD";
    readonly actionId;
    readonly actionType;
    readonly actionDescription;
    readonly actionStatus;
    readonly resultDescription;

    constructor(json_obj: any) {
        this.actionId = json_obj?.actionId;
        this.actionType = json_obj?.actionType;
        this.actionDescription = json_obj?.actionDescription;
        this.actionStatus = json_obj?.actionStatus;
        this.resultDescription = json_obj?.resultDescription;
    }
}

class Battery {
    readonly batteryCharge: number;
    readonly batteryVoltage: number;
    readonly batteryHealth: number;
    readonly charging: boolean;
    readonly reach: number;

    constructor(json_obj: any) {
        this.batteryCharge = json_obj?.batteryCharge;
        this.batteryVoltage = json_obj?.batteryVoltage;
        this.batteryHealth = json_obj?.batteryHealth;
        this.charging = json_obj?.charging;
        this.reach = json_obj?.reach;
    }
}

class SafetyState {
    static ACKNOWLEDGE_TYPE_AUTOMATIC = "AUTOMATIC";
    static ACKNOWLEDGE_TYPE_MANUAL = "MANUAL";
    static ACKNOWLEDGE_TYPE_REMOTE = "REMOTE";
    static ACKNOWLEDGE_TYPE_NONE = "NONE";
    readonly eStop;
    readonly fieldViolation;

    constructor(json_obj: any) {
        this.eStop = json_obj?.eStop ?? SafetyState.ACKNOWLEDGE_TYPE_NONE;
        this.fieldViolation = json_obj?.fieldViolation;
    }
}

class AgvMessage {
    public static type_error: string = "error";
    public static type_info = "info";
    readonly type: any;
    readonly references: Reference[];
    readonly description: any;
    readonly level: any;
    readonly hint: any;

    constructor(json_obj: any, type: string) {
        this.references = []
        if (type == AgvMessage.type_error) {
            this.type = json_obj?.errorType;
            if (json_obj?.errorReferences) {
                json_obj?.errorReferences?.forEach((el: any) => this.references.push(new Reference(el)));
            }
            this.description = json_obj?.errorDescription;
            this.level = json_obj?.errorLevel;
            this.hint = json_obj?.errorHint;
        } else if (type == AgvMessage.type_info) {
            this.type = json_obj?.infoType;
            if (json_obj?.infoReferences) {
                json_obj?.infoReferences?.forEach((el: any) => this.references.push(new Reference(el)));
            }
            this.description = json_obj?.infoDescription;
            this.level = json_obj?.infoLevel;
        }
    }
}


class Reference {
    readonly referenceKey: string;
    readonly referenceValue: string;

    constructor(json_obj: any) {
        this.referenceKey = json_obj?.referenceKey;
        this.referenceValue = json_obj?.referenceValue;
    }
}

class TypeSpecification {
    readonly seriesName: string = "";
    readonly seriesDescription: string | undefined;
    readonly agvKinematic: string = "";
    readonly agvClass: string = "";
    readonly maxLoadMass: number = 0.0;
    readonly localizationTypes: string[] = [];
    readonly navigationTypes: string[] = [];

    constructor(jsonObj: any) {
        this.seriesName = jsonObj?.seriesName;
        this.seriesDescription = jsonObj?.seriesDescription;
        this.agvKinematic = jsonObj?.agvKinematic;
        this.agvClass = jsonObj?.agvClass;
        this.maxLoadMass = jsonObj?.maxLoadMass;
        this.localizationTypes = jsonObj?.localizationTypes;
        this.navigationTypes = jsonObj?.navigationTypes;
    }
}

class PhysicalParameters {
    readonly speedMin: number = 0.0;
    readonly speedMax: number = 0.0;
    readonly accelerationMax: number = 0.0;
    readonly decelerationMax: number = 0.0;
    readonly heightMin: number = 0.0;
    readonly heightMax: number = 0.0;
    readonly width: number = 0.0;
    readonly length: number = 0.0;

    constructor(jsonObj: any) {
        this.speedMin = jsonObj?.speedMin;
        this.speedMax = jsonObj?.speedMax;
        this.accelerationMax = jsonObj?.accelerationMax;
        this.decelerationMax = jsonObj?.decelerationMax;
        this.heightMin = jsonObj?.heightMin;
        this.heightMax = jsonObj?.heightMax;
        this.width = jsonObj?.width;
        this.length = jsonObj?.length;
    }
}

class Factsheet {
    readonly timestamp: string | number;
    readonly typeSpecification: TypeSpecification;
    readonly physicalParameters: PhysicalParameters;
    readonly protocolLimits: any;
    readonly protocolFeatures: any;
    readonly agvGeometry: any;
    readonly loadSpecification: any;

    constructor(jsonObj: any) {
        this.timestamp = jsonObj?.timestamp;
        this.typeSpecification = new TypeSpecification(jsonObj?.typeSpecification)
        this.physicalParameters = new PhysicalParameters(jsonObj?.physicalParameters)
        this.protocolLimits = jsonObj?.protocolLimits
        this.protocolFeatures = jsonObj?.protocolFeatures
        this.agvGeometry = jsonObj?.agvGeometry
        this.loadSpecification = jsonObj?.loadSpecification
    }
}

class Connection {
    readonly timestamp: string | number;

    constructor(jsonObj: any) {
        this.timestamp = jsonObj.timestamp;
    }
}

export let vehicleStates: UnwrapNestedRefs<Map<string, VehicleState>>;
vehicleStates = reactive(new Map<string, VehicleState>());

export {VehicleState, AgvPosition, EdgeState, NodeState, Factsheet, Connection}
